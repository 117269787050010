export const links = [
    {
        id: 1,
        text: "Home",
        url: "#home"
    },
    {
        id: 2,
        text: "About",
        url: "#about"
    },
    {
        id: 3,
        text: "Work",
        url: "#work"
    },
    {
        id: 4,
        text: "Projects",
        url: "#projects"
    },
    {
        id: 5,
        text: "Education",
        url: "#education"
    },
    {
        id: 6,
        text: "Contact",
        url: "#contact"
    }
]