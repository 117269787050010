export const projectData = [
  {
    id: 1,
    text: 'Birthday Reminder',
    url: 'https://react-projects-1-birthday-reminder.netlify.app/',
    type: 'small',
    img: 'https://i.imgur.com/BhmK1iR.jpg',
    description: 'App to display birthday reminder',
    tech: {
      html: 'html',
      css: 'css',
      react: 'react',
      mui: 'mui',
    },
  },
  {
    id: 2,
    text: 'Tours',
    url: 'https://react-projects-2-tours.netlify.app/',
    type: 'small',
    img: 'https://i.imgur.com/BhmK1iR.jpg',
    description: 'App to display birthday reminder',
    tech: {
      html: 'html',
      css: 'css',
      react: 'react',
      mui: 'mui',
    },
  },
  {
    id: 3,
    text: 'Reviews',
    url: 'https://react-projects-3-reviews.netlify.app/',
    type: 'small',
    img: 'https://i.imgur.com/BhmK1iR.jpg',
    description: 'App to display birthday reminder',
    tech: {
      html: 'html',
      css: 'css',
      react: 'react',
      mui: 'mui',
    },
  },
  {
    id: 4,
    text: 'Accordion',
    url: 'https://react-projects-4-accordion.netlify.app/',
    type: 'small',
    img: 'https://i.imgur.com/BhmK1iR.jpg',
    description: 'App to display birthday reminder',
    tech: {
      html: 'html',
      css: 'css',
      react: 'react',
      mui: 'mui',
    },
  },
  {
    id: 5,
    text: 'Accordion-two',
    url: 'https://react-projects-4-accordion.netlify.app/',
    type: 'small',
    img: 'https://i.imgur.com/BhmK1iR.jpg',
    description: 'App to display birthday reminder',
    tech: {
      html: 'html',
      css: 'css',
      react: 'react',
      mui: 'mui',
    },
  },
  {
    id: 5,
    text: 'Menu',
    url: 'https://react-projects-5-menu.netlify.app/',
    type: 'large',
    img: 'https://i.imgur.com/BhmK1iR.jpg',
    description: 'App to display birthday reminder',
    tech: {
      html: 'html',
      css: 'css',
      react: 'react',
      mui: 'mui',
    },
  },
  {
    id: 6,
    text: 'Tabs',
    url: 'https://react-projects-6-tabs.netlify.app/',
    type: 'large',
    img: 'https://i.imgur.com/BhmK1iR.jpg',
    description: 'App to display birthday reminder',
    tech: {
      html: 'html',
      css: 'css',
      react: 'react',
      mui: 'mui',
    },
  },
  {
    id: 7,
    text: 'Tabs-two',
    url: 'https://react-projects-6-tabs.netlify.app/',
    type: 'large',
    img: 'https://i.imgur.com/BhmK1iR.jpg',
    description: 'App to display birthday reminder',
    tech: {
      html: 'html',
      css: 'css',
      react: 'react',
      mui: 'mui',
    },
  },
];
